<!-- 首頁 -->
<template>
  <!-- [Right] fixd recommend -->
  <!-- <transition name="fade">
    <router-link to="/winner-list" class="scene__recommend position-fixed btn btn-link">
      <img src="@/assets/images/btn_winner.png" class="w-100" alt="全民票選抽大獎獲獎名單">
    </router-link>
  </transition> -->

  <div class="page-index d-flex flex-column position-relative">
    <!-- 主視覺 -->      
    <div v-if="deviceMode != 'mobile'" v-show="showloading" class="kv d-flex justify-content-center align-items-center" >
        <div id="kv__desk" class="kv__desk"/>
    </div> 

    <!-- 手機版主視覺 -->      
    <div v-if="deviceMode == 'mobile'" class="kv justify-content-center align-items-center" data-aos="zoom-out" data-aos-duration="800">            
        <div id="kv__mobile" class="kv__mobile">
        </div>
    </div>

    <!-- 手機版主選單 -->
    <nav v-if="deviceMode != 'deskTop'" class="p-0 mb-2 mb-md-4 mb-lg-5">
        <div class="nav justify-content-center flex-nowrap mb-1 p-0">
              <span class="mission position-relative">
              <img @click="showToggle = '緣起'" src="@/assets/images/nav_1.gif" alt="淡水八景 - 緣起">
              <transition name="fade">
                <div v-if="showToggle == '緣起'" :class="{ 'active': showToggle == '緣起' }" class="dropdown__item">
                  <ul class="list-unstyled m-0">
                    <li>
                      <router-link to="/8views" class="w-100 text-center">淡水繽紛八景</router-link>
                    </li>
                    <li>
                      <router-link to="/history" class="w-100 text-center">淡水八景從哪來</router-link>
                    </li>
                    <li>
                      <router-link to="/traffic4.0" class="w-100 text-center">淡水八景4.0</router-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </span>
            <span class="intro position-relative ml-2">
              <img @click="showToggle = '活動辦法'" src="@/assets/images/nav_2.gif" alt="淡水八景 - 活動辦法">
              <transition name="toggle">
                <div v-if="showToggle == '活動辦法'" :class="{ 'active': showToggle == '活動辦法' }" class="dropdown__item">
                  <ul class="list-unstyled m-0">
                    <li>
                      <router-link to="/intro#part1" class="w-100 text-center">全民推薦大搜密</router-link>
                    </li>
                    <li>
                      <router-link to="/intro#part2" class="w-100 text-center">絕景攝影邀請賽</router-link>
                    </li>
                    <li>
                      <router-link to="/intro#part3" class="w-100 text-center">八景4.0全民票選抽大獎</router-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </span>
            <router-link to="/tourist-guide" class="tourist-guide"><img src="@/assets/images/nav_6.png" alt="淡水八景 - 精彩玩法路線圖"></router-link>
        </div>
        <div class="nav justify-content-center flex-nowrap p-0">
            <router-link to="/scene" class="scene"><img src="@/assets/images/nav_3.gif" alt="淡水八景 - 全民推薦大搜密"></router-link>
            <span class="photography position-relative">
              <img @click="showToggle = '絕景攝影邀請賽'" src="@/assets/images/nav_4.gif" alt="淡水八景 - 絕景攝影邀請賽">
              <transition name="toggle">
                <div v-if="showToggle == '絕景攝影邀請賽'" :class="{ 'active': showToggle == '絕景攝影邀請賽' }" class="dropdown__item">
                  <ul class="list-unstyled m-0">
                    <li>
                      <router-link to="/awards" class="w-100 text-center">得獎作品</router-link>
                    </li>
                    <li>
                      <router-link to="/outstanding" class="w-100 text-center">作品欣賞</router-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </span>
            <router-link to="/vote" class="vote"><img src="@/assets/images/nav_5.gif" alt="淡水八景 - 全民票選抽大獎"></router-link>
        </div>      
    </nav>
  </div>
      
</template>

<script>
import $ from 'jquery';
import lottie from 'lottie-web'
import kv_desktop from '@/assets/lottie/index.json'
import kv_mobile from '@/assets/lottie/index_mb.json'


export default {
  data: () => ({
    showToggle: '',
    showloading: true,
    Locking:'',
    DM:''
  }),
  mounted() {
    this.Ainit1()
    this.$nextTick(() => {
      window.addEventListener('resize', this.Ainit);
      window.addEventListener('click', this.handleClickaway);
    });

    if(this.deviceMode=='deskTop' || this.deviceMode=='pad'){
      if(window.innerHeight < window.innerWidth){
        this.DM="kv_desktop";
        // console.log("現在是桌機");
      }else{
        this.DM="kv_mobile";
        // console.log("現在是平板直");
      }
    }else{
      this.DM="kv_mobile";
      // console.log("現在是手機");
    }
    $('.l-c1 image').attr('alt', '淡水觀音山');
    $('.l-c2 image').attr('alt', '淡水情人橋');
    $('.l-c3 image').attr('alt', '淡水小白宮');
    $('.l-c4 image').attr('alt', '淡水禮拜堂');
    $('.l-c5 image').attr('alt', '淡水紅毛城');
    $('.l-c6 image').attr('alt', '淡水真理大學大禮拜堂');
    $('.l-c7 image').attr('alt', '淡水前清英國領事館官邸');
    $('.l-c8 image').attr('alt', '淡水姑娘樓');
    $('.l-c9 image').attr('alt', '淡水牛津學堂');
    $('.l-c10 image').attr('alt', '淡水八角樓(八角塔)');
    $('.l-c11 image').attr('alt', '淡水滬尾藝文休閒園區');
    $('.l-c12 image').attr('alt', '淡水馬偕古厝');
  },
	beforeUnmount() {
    window.removeEventListener('click', this.handleClickaway);
  },
  methods: {
    Ainit1() {
      var T=this;
      var ADATA;
      var AID;
      if(this.deviceMode=='deskTop' || this.deviceMode=='pad'){
        if(window.innerHeight < window.innerWidth){
          AID='kv__desk';
          ADATA=kv_desktop;
        }else{
          AID='kv__desk';
          ADATA=kv_mobile;
        }
      }else{
        AID='kv__mobile';
        ADATA=kv_mobile;
      }
      // console.log(AID, ADATA);
      if(this.Locking!=AID){
        this.Locking=AID;
        
        $("#"+AID).html('');//清空

        this.anim = lottie.loadAnimation({
          container: document.getElementById(AID), // 包含动画的dom元素
          renderer: 'svg', // 渲染出来的是什么格式
          loop: true, // 循环播放
          autoplay: false, // 自动播放
          animationData: ADATA, // 动画json的路径
        }); 

        this.anim.addEventListener("DOMLoaded", function(){
          // console.log('complete');
          for(var i=0 ; i < 13 ; i++){
            T.FInFOut('l-c'+i,'l-i'+i);
          }
        },

        setTimeout(() => {
            this.anim.play()
        }, 500))
      }
		},

    Ainit() {
      var T=this;
      var AID;
      var ADATA;
      if(this.deviceMode=='deskTop' || this.deviceMode=='pad'){
        if(window.innerHeight < window.innerWidth){
          AID='kv__desk';
          // ADATA=kv_desktop;
          ADATA = "/Files/lottie/index.json";
          // console.log("現在是桌機");
        }else{
          AID='kv__desk';
          // ADATA=kv_mobile;
          ADATA = "/Files/lottie/index_mb.json";
          // console.log("現在是平板直");
        }
      }else{
        AID='kv__mobile';
        // ADATA=kv_mobile;
        ADATA = "/Files/lottie/index_mb.json";
        // console.log("現在是手機");
      }
      if(this.Locking!=AID){
        this.Locking=AID;
        
        $("#"+AID).html('');//清空

        this.anim = lottie.loadAnimation({
          container: document.getElementById(AID), // 包含动画的dom元素
          renderer: 'svg', // 渲染出来的是什么格式
          loop: true, // 循环播放
          autoplay: false, // 自动播放
          path: ADATA, // 动画json的路径
        }); 

        this.anim.addEventListener("DOMLoaded", function(){
          // console.log('complete');
          for(var i=0 ; i < 13 ; i++){
            T.FInFOut('l-c'+i,'l-i'+i);
          }
        },

        setTimeout(() => {
            this.anim.play()
        }, 500))
      }
    },
    FInFOut(BoxClass,MsgClass) {
      $('.'+BoxClass).on('mouseover',function(){$('.'+MsgClass).css({opacity: 1});});
      $('.'+BoxClass).on('mouseleave',function(){$('.'+MsgClass).css({opacity: 0});});
    },
    comingsoon: function () {
        alert('活動即將開始，敬請期待！'); 
    },
    // 點擊空白處關閉
    handleClickaway(e) {
      if(e.target.alt==undefined){
				this.showToggle='';
			}
    },
  },
}
</script>



